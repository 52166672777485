// Saira-Thin
@font-face {
  font-family: 'SairaThin';
  src: url('../assets/fonts/Saira-Thin.ttf');
}
@mixin SairaThin {
  font-family: SairaThin;
}
// Saira-Light
@font-face {
  font-family: 'SairaLight';
  src: url('../assets/fonts/Saira-Light.ttf');
}
@mixin SairaLight {
  font-family: SairaLight;
}
// Saira-Regular
@font-face {
  font-family: 'SairaRegular';
  src: url('../assets/fonts/Saira-Regular.ttf');
}
@mixin SairaRegular {
  font-family: SairaRegular;
}
// Saira-Medium
@font-face {
  font-family: 'SairaMedium';
  src: url('../assets/fonts/Saira-Medium.ttf');
}
@mixin SairaMedium {
  font-family: SairaMedium;
}
// Saira-Bold
@font-face {
  font-family: 'SairaBold';
  src: url('../assets/fonts/Saira-Bold.ttf');
}
@mixin SairaBold {
  font-family: SairaBold;
}
// Saira-ExtraBold
@font-face {
  font-family: 'SairaExtraBold';
  src: url('../assets/fonts/Saira-ExtraBold.ttf');
}
@mixin SairaExtraBold {
  font-family: SairaExtraBold;
}
// Inter-Regular
@font-face {
  font-family: 'InterRegular';
  src: url('../assets/fonts/Inter-Regular.ttf');
}
@mixin InterRegular {
  font-family: InterRegular;
}