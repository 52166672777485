@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
}

.container {
  width: stretch;
  gap: 3rem;
  padding-top: 5rem;
  padding-bottom: 1rem;

  @include s {
    padding-top: 3rem;
    gap: 2rem;
  }
  @include xs {
    padding-top: 2rem;
    // gap: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: stretch;
    gap: 1.5rem;

    @include s {
      align-items: center;
      justify-content: flex-start;
    }

    color: $gray-dark;
    .title {
      @include SairaMedium;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 104%; /* 2.6rem */
      text-align: center;
      width: 75%;

      @include s {
        align-items: flex-start;
        text-align: left;
        font-size: 2rem;
        width: 90%;
      }
      @include xs {
        font-size: 1.875rem;
        width: 100%;
      }
    }
    .subtitle {
      @include SairaLight;
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 1.5rem */

      @include m {
        text-align: left;
      }
    }
  }
  .features {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6rem;
    padding-bottom: 1.5rem;
    // margin: 0 -2rem 0 -1rem;
    // margin: 0 -2rem 0 0;

    @include xl {
      gap: 5rem;
    }
    @include l {
      gap: 3rem;
    }

    @include m {
      gap: 2rem;
      overflow-x: scroll;
      padding: 0 2rem 2rem 0;
      margin: 0 -2rem 0 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include s {
      gap: 1rem;
    }
    @include xs {
      flex-direction: column;
      gap: .5rem;
    }

    .feature {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      border-radius: .5rem;
      width: calc(33.333%);

      @include s {
        gap: .5rem;
      }
      @include xs {
        gap: .25rem;
        width: 100%;
      }

      .preview {
        width: 100%;
      }
      .text {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1rem 0;
        color: $secondary-color;

        @include s {
          gap: 1rem
        }
        @include xs {
          gap: .5rem
        }

        .title {
          @include SairaMedium;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 1.5rem */
          color: $primary-color;

          @include m {
            font-size: 1.25rem;
          }
        }
        .description {
          @include SairaLight;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 300;
          line-height: 104%; /* 1.3rem */
          color: #000;

          @include s {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .line {
    margin-top: -2.5rem;
    width: 100%;
    border: 1px solid $neutrals4;
  }

}