@import '../../styles/mainStyles.scss';

.swiper {
  padding-bottom: 2rem;

  @include s {
    padding-bottom: 0;
  }
}
.swiperSlide {
  transition: .25s;
}
.slide {
  width: 100%;
  height: 100%;
}

.horizontal {
  bottom: 0 !important;
  width: 100% !important;

  @include s {
    width: 100% !important;
  }
}
.vertical {
  display: none;
}
.bullet, .bulletActive {
  display: none;
  width: .75rem;
  height: .75rem;
  margin-right: .25rem;
  border-radius: 50%;
  background: $gray-fifty;

  &.active {
    display: inline-block;
    @include s {
      display: none;
    }
  }
}