@import '../../styles/mainStyles.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 1rem;
  height: 1rem;
  border: 3px solid $light;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spinner 1.75s linear infinite;
}