@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background: $primary-gradient;
  border-radius: 2.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include m {
    border-radius: 2rem;
  }
  @include s {
    border-radius: 1.5rem;
  }
  @include xs {
    border-radius: 1.25rem;
  }
}

.container {
  width: stretch;
  gap: 3rem;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: stretch;

    @include m {
      justify-content: flex-start;
      // width: 70%;
    }
    .title {
      @include SairaLight;
      font-size: 2rem;
      font-style: normal;
      font-weight: 300;
      line-height: 104%;
      color: $ternary-color;

      @include s {
        width: 90%;
        align-items: flex-start;
        font-size: 1.75rem;
      }
      @include xs {
        font-size: 1.5rem;
      }
    }
  }
  .benefits {
    display: flex;
    flex-direction: row;
    gap: 3rem;

    @include m {
      flex-direction: column;
    }
    .column {
      transition: 1s;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 30%;

      @include m {
        width: 100%;
      }

      .benefit {
        transition: .5s;
        color: $light-fifty;
        @include SairaRegular;
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-align: start;

        @include m {
          width: fit-content;
        }

        &.selected {
          color: $primary-color !important;
          @include SairaMedium;
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 2.5rem */
          text-align: end;

          @include m {
            text-align: start;
          }
        }

        &:hover {
          font-weight: 700;
          color: $ternary-color;
          cursor: pointer;
        }
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 70%;

      @include m {
        width: 100%;
      }

      &.developers {
        align-items: center;
        justify-content: center;
      }
      &.investors {
        align-items: center;
        justify-content: center;
      }
      &.suppliers {
        align-items: flex-end;
        justify-content: flex-end;
      }

      span {
        @include SairaLight;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 300;
        line-height: 120%; /* 1.8rem */
        color: $ternary-color;

        @include m {
          font-size: 1.25rem;
        }
        @include s {
          font-size: 1rem;
        }
      }
    }
  }
}