$lightness-sensitive: 50;

$primary-color: #D80587;
$secondary-color: #310C6C;
$ternary-color: #F1F1F1;
$primary-gradient: linear-gradient(294deg, #D80587 3.98%, #680677 37.49%, #350770 72.06%, #310C6C 100%);
$purple-blue-gradient: linear-gradient(90deg, #D80587 0%, #310C6C 100%);
$real-estate-gradient: linear-gradient(90deg, #6BB2FF 0%, #68A8FE 8%, #5B79FB 50%, #535BF9 82%, #5051F9 100%);

$primary-button-color: $primary-color;
$secondary-button-color: $primary-color;
$footer-bg-color: $primary-gradient;
$icon-color: $primary-color;
