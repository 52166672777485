@import '../../styles/mainStyles.scss';

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // background: $white;

  height: 100%;
  width: 100%;

  border-radius: .625rem;
}
.shadow {
  box-shadow: 0 .25rem 1.25rem $shadow-color;
}