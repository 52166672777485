@import "../../styles/mainStyles.scss";

.section {
  display: flex;
  flex-direction: row;
  width: stretch;
  overflow: hidden;
}
.slider {
  width: 100% !important;
}
.slide {
  display: flex !important;
  flex-direction: row !important;
  width: min-content !important;
  gap: 1.5rem !important;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  span {
    @include SairaThin;
    color: $ternary-color;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 111%; /* 1.665rem */
    white-space: nowrap !important;
  }
}