@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background-image: url('../../assets/images/banner-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  height: 888px;
  @include m {
    height: 750px;
    text-align: center;
  }
  @include s {
    height: 736px;
  }
  @include xs {
    background-image: url('../../assets/images/banner-background-mobile.jpg');
    height: 736px;
  }
}

.container {
  justify-content: flex-end;
  gap: 1.75rem;
  width: stretch;
  height: 100%;
  position: relative;


  @include s {
    gap: 1.5rem;
  }
  @include xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .title {
    position: absolute;
    top: 35%;
    @include SairaThin;
    font-size: 2.5rem;
    font-weight: 400;
    color: $light;
    line-height: 100%;
    letter-spacing: -0.01em;
    text-align: left;
    width: 50%;

    strong {
      @include SairaRegular;
    }

    @include m {
      width: 70%;
      font-size: 2rem;
    }
    @include s {
      width: 90%;
      font-size: 2rem;
    }
    @include xs {
      font-size: 1.875rem;
      width: 85%;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10%;
    width: 100%;

    @include s {
      flex-direction: column;
      gap: 5rem;
    }

    .linkButton {
      text-decoration: none;
      white-space: nowrap;
      .tokenizeButton {
        text-transform: uppercase;
        border-radius: .5rem;
        padding: 0 1rem;
        @include xs {
          min-height: unset;
          height: 1.875rem;
        }
        &:hover {
          cursor: pointer;
          opacity: .8;
        }
      }
    }
    .textWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 3rem;
      width: fit-content;

      @include s {
        padding: 0 .5rem;
        width: 100%;
        gap: 2rem;
      }
      @include xs {
        gap: .75rem;
      }

      .text {
        position: relative;
        @include SairaThin;
        font-size: 1rem;
        font-weight: 400;
        color: $light;
        line-height: 114.4%;
        text-align: left;
        width: 20%;

        svg {
          position: absolute;
          top: -1rem;
          left: -1rem;
        }

        @include m {
          width: 25%;
        }

        @include s {
          width: 50%;
        }

        strong {
          @include SairaRegular;
        }
      }
    }
  }
}