@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.container {
  width: stretch;
  gap: 3rem;
  padding-bottom: 0;

  @include m {
    gap: 2rem;
  }
  @include s {
    gap: 1.5rem;
  }
  @include xs {
    gap: 1rem;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: stretch;

    @include m {
      justify-content: flex-start;
    }
    .title {
      @include SairaLight;
      font-size: 2rem;
      font-style: normal;
      font-weight: 300;
      line-height: 104%;

      @include m {
        align-items: flex-start;
        font-size: 1.75rem;
      }
      @include xs {
        font-size: 1.5rem;
      }
    }
  }
  .partners {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    padding: 0 2rem 6rem 1rem;
    margin: 0 -2rem 0 -1rem;
    justify-content: space-between;

    @include xl {
      gap: .5rem;
    }

    img {
      object-fit: contain;
    }

    @include l {
      flex-wrap: nowrap;
      gap: 3rem;
      overflow-x: scroll;
      grid-template-areas: unset;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include m {
      padding-bottom: 5rem;
    }
    @include s {
      padding-bottom: 4rem;
    }
    @include xs {
      padding-bottom: 3rem;
    }
  }
}

.sliderBanner {
  background: $purple-blue-gradient;
}
.slider {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: stretch !important;
  overflow: hidden !important;
  padding: .5rem 0;
  transition-timing-function: linear !important;
  .swiper-wrapper {
    transition-timing-function: linear;
  }
}
.swiperSlide {
  flex-shrink: 0;
  transition: 1s;
  width: min-content !important;
  transition-timing-function: linear !important;
}
.labelSlide {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem 0 0;
  width: min-content !important;

  span {
    @include SairaThin;
    color: $ternary-color;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 111%; /* 1.665rem */
    white-space: nowrap;
  }
}