@import '../../styles/mainStyles.scss';

.container {
  display: flex;
  flex-direction: row;
  text-align: start;
  gap: 1rem;
  padding: 1.5rem 6rem;
  margin: auto;

  max-width: 1240px;
  > img {
    max-height: 80vh;
  }

  @include xl {
    padding: 1rem 4rem;
    gap: 3.125rem;
    > img {
      width: 31rem;
    }
  }
  @include l {
    padding: 1rem 3rem;
    gap: 3rem;
    > img {
      width: 29rem;
    }
  }
  @include m {
    padding: 1rem 2rem;
    gap: .25rem;
    > img {
      width: 25rem;
    }
  }
  @include s {
    flex-direction: column;
    gap: 0;
    > img {
      max-height: none;
      width: 100%;
    }
  }
  @include xs {
    padding: .75rem 1.5rem 1.5rem;
  }
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}