@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background: $gray;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include m {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
  @include s {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  @include xs {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  }
}

.container {
  width: stretch;
  gap: 3rem;
  overflow: hidden;

  @include m {
    flex-direction: column;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin-top: -6rem;

    @include m {
      margin-top: 0;
      justify-content: flex-start;
    }

    .title {
      @include SairaMedium;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 104%;
      color: $ternary-color;

      @include s {
        font-size: 2rem;
      }
      @include xs {
        font-size: 1.875rem;
      }
    }
  }
  .team {
    width: stretch;
    overflow: hidden;

    @include xs {
      // padding-right: 1rem;
    }

    .person {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.25rem;
      // width: 100%;

      .line {
        width: 100%;
        border: 1px solid $primary-color;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        .name {
          @include SairaLight;
          font-size: 1.5rem;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 104%;
          color: $ternary-color;
          text-transform: uppercase;

          @include m {
            font-size: 1.25rem;
          }
          @include s {
            font-size: 1rem;
          }
        }
        .job {
          @include SairaLight;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 104%;
          color: rgba(241, 241, 241, 0.70);
        }
      }
    }
  }
}