@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
}

.container {
  width: stretch;
  gap: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include s {
    gap: 2rem;
  }
  @include xs {
    gap: 1rem;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: stretch;

    @include s {
      justify-content: flex-start;
    }
    .title {
      @include SairaLight;
      font-size: 2rem;
      font-style: normal;
      font-weight: 300;
      line-height: 104%;

      @include s {
        align-items: flex-start;
        font-size: 1.75rem;
      }
      @include xs {
        font-size: 1.5rem;
      }
    }
  }
  .worries {
    display: grid;
    grid-template-areas: "a a a";
    gap: 1rem;
    grid-auto-rows: min-content;
    padding: 0 2rem 2rem 1rem;
    margin: 0 -2rem 0 -1rem;

    @include m {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .card {
      flex-direction: column;
      justify-content: flex-start;
      border-radius: .5rem;
      min-width: 17.333rem;

      &:first-child {
        .text {
          .title {
             width: 70%;
           }
        }
      }

      .preview {
        height: 21.0625rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-position: left;
          object-fit: cover;
          transition: transform .5s;
        }
      }
      .text {
        display: inherit;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem 1.5rem 1rem;
        color: $secondary-color;

        .title {
          @include SairaMedium;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          width: 90%;
          @include m {
            font-size: 1.25rem;
            width: 100%;
          }
        }
        .description {
          @include SairaLight;
          font-size: 1rem;
          font-style: normal;
          font-weight: 300;
          line-height: 105%; /* 1.05rem */
          width: 70%;

          @include s {
            font-size: .875rem;
            width: 80%;
          }
        }
      }

      &:hover {
        img {
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
        }
      }
    }
  }
}