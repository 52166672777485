@import '../../styles/mainStyles.scss';

.section {
  position: relative;
  overflow: hidden;
  background-image: url('../../assets/images/combine-sections-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  @include s {
    background-image: url('../../assets/images/combine-sections-bg-mobile.jpg');
  }
}